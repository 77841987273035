export interface IProducer {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    loginId?: string;
    password?: string;
    phone?: string;
    systemid?: string;
    entity?: string;
    recaptchaResponse?: any;
    GUID?: string;
    oldPassword?: string;
    newPassword?: string;
    involvements?: [];
}

export class Producer implements IProducer {
    firstName?: string = '';
    lastName?: string;
    emailAddress?: string;
    loginId?: string;
    password?: string;
    phone?: string;
    systemid?: string;
    entity?: string;
    recaptchaResponse?: any;
    GUID?: string;
    oldPassword?: string;
    newPassword?: string;
    involvements?: [];
}
