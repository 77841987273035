interface IProducerResetPassword {
    loginId?: string;
    newPassword?: string;
    token?: string;
}

export class ProducerResetPassword implements IProducerResetPassword {
    loginId?: string;
    newPassword?: string;
    token?: string;
}
